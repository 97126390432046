
.bar-chart-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.bar-chart-container canvas {
    font-style: normal;
    width: 100% !important;
    height: 90% !important;
}

@media screen and (max-width: 991px) {
    .bar-chart-container canvas {
        width: 100% !important;
        height: 75% !important;
    }
}