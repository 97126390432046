.login-box {
    width: 100%;
    border-radius: 5px;
    margin: 0 auto;
}

.login-card {
    max-height: 400px;
    width: 35%;
    padding: 2rem;
    background-color: #ffffff;
    border: 1px solid #ccc;
}

.login-card h2 {
    text-align: center;
    margin-bottom: 1.5rem;
}

.form-control {
    border: 1px solid #ccc;
    background-color: #fff !important;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    width: 100%;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .login-card {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .login-card {
        width: 90%;
    }
}