@import '../../../styles/variables.scss';


.roles-tree {
    font-family: Arial, sans-serif;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    #js-tree {
        background-color: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        padding: 10px;
        overflow-y: auto;

        .jstree-node {
            margin-bottom: 5px;
        }

        .jstree-anchor {
            color: #333;
            padding: 5px;
            border-radius: 3px;
            transition: background-color 0.2s ease;

            &:hover {
                background-color: #f0f0f0;
            }
        }

        .jstree-clicked {
            background-color: #e6f3ff !important;
            box-shadow: none;
        }

        .jstree-icon {
            color: #666;
        }
    }
}

// Custom scrollbar styles
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}