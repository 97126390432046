.loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
    z-index: 9999; /* High z-index to ensure it's on top */
}