@import '../../../styles/variables.scss';

.cities {
    .info {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 15px;

        button {
            padding: 5px;
            cursor: pointer;
            border-radius: 10px;
            background-color: $primary-soft-bg;
            color: white;
            border: 1px solid transparent;

            &:hover {
                background-color: $primary-dark-bg;
            }
        }
    }
}