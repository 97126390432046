.stat-container {
    padding: 0 !important;
}

.stat-val {
    position: absolute;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    justify-content: center;
    align-items: center;
    padding: 0 12px;
}

.stat-val span {
    font-size: 3em;
    color: rgba(0, 0, 0, 0.8);
}

.stat-header {
    height: 10%;
}

.stat-body {
    position: relative;
    height: 90%;
}

.stat-loading {
    width: 150px;
}

.pagination-container {
    margin-top: 20px !important;
}



