@import '../../styles/variables.scss';

.dataTable {

  .dataGrid {
    background: white;
    padding: 20px 20px 5px 20px;
    border-radius: 15px;

    .css-1knaqv7-MuiButtonBase-root-MuiButton-root {
      color: black;
    }

    .MuiTablePagination-displayedRows {
      margin: 0;
    }

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }

    .action {
      display: flex;
      gap: 15px;

      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }
}