.role-form-modal {
    .custom-modal {
        .name-item {
            width: 100% !important;

            input[name="name"] {
                width: 100%;
                box-sizing: border-box;
            }
        }
    }
}