.adminNavbar {
  background-color: rgba(255,255,255,1) !important;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15) !important;
  box-sizing: border-box;

  .logo {
    display: flex;
    align-items: center;
    font-weight: bold;
    gap: 10px;
  }

  .icons {
    .notification {
      position: relative;

      span {
        background-color: red;
        color: white;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        position: absolute;
        top: -10px;
        right: -10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }
    }

    display: flex;
    align-items: center !important;
    gap: 20px;

    .user {
      position: relative; // Added for proper dropdown positioning
      display: flex;
      align-items: center !important;
      gap: 10px;
      cursor: pointer; // Indicates clickable area

      img {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        object-fit: cover;
      }

      .dropdown-menu {
        position: absolute;
        background-color: #ffffff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border: 1px solid #ddd;
        right: 0;
        top: 40px;
        z-index: 1000;
        padding: 8px 0;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        min-width: 100px;

        button {
          padding: 8px 12px;
          cursor: pointer;
          background: none;
          border: none;
          text-align: left;
          width: 100%;
          &:hover {
            background-color: #f0f0f0;
          }
        }
      }
    }
  }
}
