@import '../../styles/variables';

.single {
    display: flex;

    .view {
        flex: 1;
        .info {

            .topInfo {
                display: flex;
                align-items: center;
                gap: 20px;

                img {
                    width: 100px;
                    height: 100px;
                    border-radius: 20px;
                    object-fit: cover;
                }

                h1 {
                    font-weight: 500;
                }

                button {
                    padding: 5px;
                    cursor: pointer;
                    border-radius: 10px;
                    background-color: $primary-soft-bg;
                    color: white;
                    border: 1px solid transparent;

                    &:hover {
                        background-color: $primary-dark-bg;
                    }
                }
            }
            .details {

                font-size: 18px;

                .item {
                    margin: 30px 0;

                    .itemTitle {
                        font-weight: 600;
                        margin-right: 10px;
                        text-transform: capitalize;
                    }
                }
            }
        }

        hr {
            width: 90%;
            height: 0;
            border: 0.5px solid gray;
            margin: 20px 0;
        }

        .chart {

            margin-top: 50px;
            width: 80%;
            height: 400px;
        }

    }
    .activities {
        flex: 1;

        h2 {
            margin-bottom: 20px;
        }

        ul {
            li {
                list-style-type: none;
                position: relative;
                width: 1px;
                padding-top: 5px;
                background-color: #f45b69;
            }
        }
    }
}