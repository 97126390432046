@import "../../styles/variables.scss";

.admin {
  font-family: $font-family-base;
  background-color: #f2f6fc;
  color: #212832;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.admin-navbar-container {
  position: fixed;
  flex: 1;
  z-index: 1039;
  width: 100%;
}

.admin-main-container {
  flex: 11;
  display: flex;
}

.menuContainer {
  width: 240px;
  height: 100vh;
  background-color: white;
  box-shadow: 0.15rem 0 1.75rem 0 rgba(33, 40, 50, 0.15) !important;
  position: fixed;
  z-index: 1038;
  padding: 92px 20px 20px 20px;
  border-right: 2px solid rgba(0, 0, 0, 0.175);
}

.contentContainer {
  padding: 72px 0 0 240px;
  width: 100%;
}

.main-content {
  padding: 20px 50px 50px 50px;
}

.admin-footer-container {
    flex: 1;
}