@import '../../../styles/variables.scss';

.roles-container {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    .roles-header {
        flex: 1;
    }

    .roles-main {
        grid-row: 2;
        flex: 9;
        display: grid;
        grid-template-columns: 2fr 3fr;
        gap: 1rem;
        overflow: hidden;

        .roles-tree-container {

        }

        .role-details-container {
            background-color: #ffffff;
            border-radius: 8px;
            overflow-y: auto;
            padding: 1rem;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
    }
}

@media (max-width: 768px) {
    .roles-container {
        .roles-main {
            grid-template-columns: 1fr;
            grid-template-rows: auto 1fr;

            .roles-tree-container {
                grid-row: 1;
                max-height: 300px;
            }

            .roles-details-container {
                grid-row: 2;
            }
        }
    }
}