@import '../../../styles/variables.scss';

.form-modal {

    .error {
        color: #fc4545;
        font-size: 0.875em;
        margin-top: 2px;
    }

    label {
        font-size: 14px;
        color: $soft-color;
    }

    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1040;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.724);
    display: flex;
    align-items: center;
    justify-content: center;

    .custom-modal {
        padding: 50px;
        border-radius: 10px;
        background-color: $main-bg;
        position: relative;

        h1 {
            margin-bottom: 40px;
            font-size: 24px;
            color: $main-color;
        }

        .close {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            color: #ffffff;
            font-size: 32px;

            &:hover {
                top: 12px;
                right: 12px;
                font-size: 30px;
            }
        }

        form {
            display: flex;
            flex-wrap: wrap;
            max-width: 500px;
            justify-content: space-between;

            .checkbox-list {

                width: 100%;

                .checkbox {

                    .checkbox-text {
                        color: rgba(221, 221, 221, 0.6);
                        margin-left: 5px;
                        font-size: 14px;
                    }

                }
            }

            .item {
                width: 45%;
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-bottom: 20px;

                input, select {
                    padding: 10px;
                    background-color: transparent;
                    color: white;
                    outline: none;
                    border: 1px solid $soft-color;
                    border-radius: 5px;
                }

                select {
                    option {
                        color: black;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            button {
                width: 100%;
                margin-top: 10px;
                padding: 10px;
                cursor: pointer;
                border: none;
                border-radius: 5px;
                background-color: $primary-soft-bg;
                color: $main-color;

                &:hover {
                    background-color: $primary-dark-bg;
                }
            }
        }
    }
}