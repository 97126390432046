$font-family-base: 'Metropolis', -apple-system, BlinkMacSystemFont, 'Segoe UI',
Roboto, 'Helvetica Neue', Arial, sans-serif,
'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';

// Background
$main-bg: #2a3447;
$soft-bg: rgba(56, 66, 86, 0.59);
$dark-bg: #222b3c;

// Text
$main-color: white;
$soft-color: rgba(221, 221, 221, 0.6);
$dark-color: #2a3447;

// Buttons
$primary-soft-bg: #337ab7;
$primary-dark-bg: #14558c;

$primary: #0061f2;
$secondary: #6900c7;
$success: #00ac69;
$danger: #e81500;
$warning: #f4a100;
$info: #00cfd5;

