
.value-box {
    height: 300px;
    width: 98%;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 20px;
}

@media screen and (max-width: 991px) {
    .value-box {
        width: 100%;
        height: 250px;
    }
}