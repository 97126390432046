@import "../../styles/variables.scss";

.menu {

  .item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;

    .title {
      font-size: 12px;
      font-weight: 600;
      color: #333333;
      text-transform: uppercase;
    }

    .listItem {
      text-decoration: #333333;
      color: #69707a;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      border-radius: 5px;

      &:hover {
        background-color: $soft-bg;
        color: #ffffff;
      }

    }
  }

}