.number-range-slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    height: 45px;
    margin-bottom: 10px;
}

#ageRange {
    width: 90%;
    margin-bottom: 5px;
}

.number-range-slider-label {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

@media screen and (max-width: 991px) {
    .number-range-slider-label {
        font-size: 14px;
    }
}

@media screen and (max-width: 767px) {
    .number-range-slider-label {
        font-size: 11px;
    }
}