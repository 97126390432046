@import '../../../styles/variables.scss';

.role-details {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    color: #333333;
    font-size: 16px;
    line-height: 1.5;
    overflow-y: auto;

    .no-role-selected {
        font-style: italic;
        color: #6c757d;
        font-size: 18px;
        text-align: center;
        margin-top: 20px;
    }

    .role-details-header {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        border-bottom: 2px solid #333333;
        padding-bottom: 12px;

        .role-name {
            flex: 3;
            color: #333333;
            font-size: 24px;
            font-weight: 600;
            margin: 0;
        }

        .role-details-actions {
            flex: 2;
            display: flex;
            gap: 15px;

            .add-role-button {
                flex: 1;
                padding: 8px 12px;
                cursor: pointer;
                border-radius: 6px;
                background-color: $primary-soft-bg;
                color: white;
                border: none;
                font-size: 14px;
                transition: background-color 0.2s ease;

                &:hover {
                    background-color: $primary-dark-bg;
                }
            }

            .delete-role-button {
                flex: 1;
                padding: 8px 12px;
                cursor: pointer;
                border-radius: 6px;
                background-color: #ff2727;
                color: white;
                border: none;
                font-size: 14px;
                transition: background-color 0.2s ease;

                &:hover {
                    background-color: #c22626;
                }
            }
        }


    }

    .role-info, .role-users, .role-permissions {
        margin-bottom: 28px;
        background-color: #f8f9fa;
        padding: 16px;
        border-radius: 8px;

        h3 {
            color: #2c3e50;
            margin-bottom: 16px;
            font-size: 20px;
            font-weight: 600;
            border-bottom: 1px solid #dee2e6;
            padding-bottom: 8px;
        }

        p {
            margin-bottom: 8px;
            font-size: 14px;
        }
    }

    .role-users {
        ul {
            list-style-type: none;
            padding-left: 0;

            li {
                background-color: #ffffff;
                margin-bottom: 8px;
                padding: 8px 12px;
                border-radius: 6px;
                font-size: 14px;
                transition: background-color 0.2s ease;

                &:hover {
                    background-color: #e9ecef;
                }
            }
        }
    }

    .role-permissions {
        .permissions-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;

            div {
                background-color: #ffffff;
                padding: 8px 12px;
                border-radius: 6px;
                font-size: 14px;
                transition: background-color 0.2s ease;

                &:hover {
                    background-color: #e9ecef;
                }
            }
        }
    }

    strong {
        font-weight: 600;
        color: #2c3e50;
    }
}

@media (max-width: 768px) {
    .role-details {
        .role-details-header {
            flex-direction: column;
            align-items: flex-start;

            .role-name {
                margin-bottom: 12px;
            }

            button {
                width: 100%;
            }
        }

        .role-permissions {
            .permissions-grid {
                grid-template-columns: 1fr; // Switch to a single column on smaller screens
            }
        }
    }
}