@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');


body {
    font-family: 'Montserrat', sans-serif !important;
    background-color: #f4f4f4 !important;
    color: #333;
}

.row {
    margin: 0 !important;
    padding: 0 !important;
}


