.filter-box {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 30px 30px 10px 30px;
    position: relative;
}

.lightpick__select-years {
    max-height: 100px;
    overflow-y: auto;
}

.curtain-logo {
    width: 30px;
    height: 30px;
    margin-top: 20px;
    background-color: transparent;
    color: #666666bb;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
}

@media screen and (max-width: 991px) {
    .filter-box {
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
        padding: 20px 20px;
    }

    .lightpick__month {
        width: 250px;
    }

    .lightpick__select-years {
        max-height: 100px;
        overflow-y: auto;
    }

    .curtain-logo {
        margin: 0;
        width: 30px;
        height: 30px;
        background-color: rgba(255, 255, 255, 0.8);
        color: #666;
    }
}