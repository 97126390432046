.chart-box {
    height: 370px;
    width: 98%;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 20px 30px;
}

.stat-header-button {
    background: none;
    border: none;
}

.info-icon-background {
    background-color: rgba(0, 0, 0, 0.85);
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    height: 25px;
    width: 25px;
}

.info-icon {
    color: #ffffff;
    font-size: 15px;
}

.statbox-three-dots {
    font-size: 17px;
    font-weight: bold;
}

.context-menu {
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}

.context-menu-content {
    position: relative;
    z-index: 1;
    padding: 15px;
    background-color: #f9f9f9;
}

.list-group {
    list-style-type: none;
}

.list-group-item {
    padding: 10px 15px;
    cursor: pointer;
}

.list-group-item:hover {
    background-color: #d6d6d6;
}

.stat-header-buttons-container,
.stat-header-context-menu-button-container {
    padding-right: 0 !important;
}

@media screen and (max-width: 991px) {
    .chart-box {
        height: 300px;
        width: 100%;
    }
}